import type { CloseButtonProps } from "node_modules/react-toastify/dist/components";
import { toast, ToastContainer, Zoom, type ToastOptions } from "react-toastify";

interface ToastProps {
  title: string;
  text: string;
}

export const useToast = () => {
  const defaultConfig: ToastOptions<unknown> = {
    position: "bottom-center",
    hideProgressBar: true,
    autoClose: 5000,
    transition: Zoom,
    closeButton: CloseButton,
  };

  const toastSuccess = ({ title, text }: ToastProps) => {
    toast.success(<Message title={title} text={text} />, defaultConfig);
  };

  const toastError = ({ title, text }: ToastProps) => {
    toast.error(<Message title={title} text={text} status="error" />, defaultConfig);
  };

  return {
    toastSuccess,
    toastError,
    ToastContainer,
  };
};

interface MessageProps {
  title: string;
  text: string;
  status?: "success" | "error";
}

const Message = ({ title, text, status = "success" }: MessageProps) => {
  const statusColor = {
    success: "bg-fluxonOffBlue",
    error: "bg-error",
  };

  const statusIcon = {
    success: "ph-check-circle",
    error: "ph-x-circle",
  };

  return (
    <div className="relative flex items-center justify-center">
      <div
        className={`flex items-center justify-center gap-1.5 rounded-2xl ${statusColor[status]} px-[2rem] py-5 pr-[4.5rem] max-md:rounded-none`}
      >
        <i className={`ph ${statusIcon[status]} text-body text-[1.5rem]`} />
        <p className="text-body text-white">
          <span>{title}</span> {text && <span className="opacity-70">{text}</span>}
        </p>
      </div>
    </div>
  );
};

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <i
    onClick={closeToast}
    className="ph ph-x absolute inset-y-0 right-8 m-auto size-[1.5rem] cursor-pointer text-[1.5rem] text-fluxonOffWhite"
  />
);
