import type { TextareaHTMLAttributes } from "react";
import { forwardRef, useState } from "react";
import type { FieldError, UseFormRegisterReturn } from "react-hook-form";

type InputProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  Partial<UseFormRegisterReturn> & {
    error?: FieldError;
  };

const Textarea = forwardRef<HTMLTextAreaElement, InputProps>((props, ref) => {
  const [characterCount, setCharacterCount] = useState(0);
  const { maxLength, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (maxLength && inputValue.length > maxLength) return;
    setCharacterCount(inputValue.length);

    // Forward onChange event
    onChange?.(event);
  };

  return (
    <div className="relative flex w-full">
      <textarea
        ref={ref}
        className="text-body size-full min-h-[3.75rem] resize-none rounded border border-transparent bg-white bg-opacity-10 p-4 placeholder-white outline-none transition-all hover:bg-opacity-[12%] focus:border-zinc-500 focus:bg-opacity-[12%]"
        onInput={handleChange}
        {...props}
      />

      {maxLength && (
        <p className="text-body-small absolute bottom-0 right-0 p-4 text-dark-60">
          {characterCount}/256
        </p>
      )}
    </div>
  );
});

export default Textarea;
