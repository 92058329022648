import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import type { FieldError, UseFormRegisterReturn } from "react-hook-form";

const mapNameToIcon = {
  company: "buildings",
  email: "envelope",
  phone: "phone",
};

type InputProps = InputHTMLAttributes<HTMLInputElement> &
  Partial<UseFormRegisterReturn> & {
    error?: FieldError;
  };

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const icon = mapNameToIcon[props.name as keyof typeof mapNameToIcon];

  return (
    <label className="relative block">
      {icon && <Icon name={icon} />}
      <input
        ref={ref}
        className="text-body h-[3.75rem] w-full rounded border border-transparent bg-white bg-opacity-10 pr-[3.25rem] placeholder-white outline-none transition-all hover:bg-opacity-[12%] focus:border-zinc-500 focus:bg-opacity-[12%]"
        style={{
          paddingLeft: icon ? "3.25rem" : "1rem",
          borderColor: props.error ? "#FF461E" : undefined,
        }}
        {...props}
      />
      {props.error && <ErrorIcon />}
    </label>
  );
});

export default Input;

const Icon = ({ name }: { name: string }) => {
  return (
    <div className="absolute left-0 top-0 flex h-full w-[3.25rem] items-center justify-center">
      <i className={`ph ph-${name} text-2xl`} />
    </div>
  );
};

const ErrorIcon = () => {
  return (
    <div className="absolute right-0 top-0 flex h-full w-[3.25rem] items-center justify-center">
      <i className="ph ph-warning text-2xl text-error" />
    </div>
  );
};
